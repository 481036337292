<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">Transaction Monitoring By Route</h1>
      <div class="flex justify-start items-start">
        <button
          class="mr-3 drop-shadow-2xl ring-1 border-blue-800 bg-white py-1 text-xs px-3 rounded-md mt-2"
          @click="
            $router.push({
              name: 'TransactionMonitoringPortalTwo',
            })
          "
        >
          &laquo; Back
        </button>
      </div>
      <!-- <div class=" flex justify-end items-end">
        <select
          class="p-3 mr-3 drop-shadow-2xl ring-1 border-blue-800 bg-white py-3 text-sm px-3 rounded-md mr-2"
          v-model="statusSelect"
        >
          <option value="">Today</option>
          <option value="rrn">RRN</option>
          <option value="stan">STAN</option>
          <option value="terminalId">Terminal ID</option>
        </select>
      </div> -->
      <div class="flex flex-wrap xl:-mx-5">
        <div class="w-full xl:my-5 xl:px-5 xl:w-1/3">
          <!-- Column Content -->
          <div class="drop-shadow-lg bg-white py-12 rounded-md">
            <p class="text-center font-semibold text-sm">Total Transactions</p>
            <h3 class="text-center font-bold text-2xl">512</h3>
          </div>
        </div>

        <div class="w-full xl:my-5 xl:px-5 xl:w-1/3">
          <!-- Column Content -->
          <div class="drop-shadow-lg bg-green-100 py-10 rounded-md">
            <p class="text-center font-semibold text-sm">
              Successful Transactions
            </p>
            <h3 class="text-center font-bold text-2xl">512</h3>
            <p
              class="bg-transparent text-green-600 mt-2 text-xs font-bold text-center"
            >
              View
            </p>
          </div>
        </div>

        <div class="w-full xl:my-5 xl:px-5 xl:w-1/3">
          <!-- Column Content -->
          <div class="drop-shadow-lg bg-red-100 py-10 rounded-md">
            <p class="text-center font-semibold text-sm">Failed Transactions</p>
            <h3 class="text-center font-bold text-2xl">512</h3>
            <p
              class="bg-transparent text-red-600 mt-2 text-xs font-bold text-center"
            >
              View
            </p>
          </div>
        </div>
      </div>
      <div class="mb-5 flex mt-20">
        <div class="w-1/2 text-sm">
          {{ selectedRoute }} transaction breakdown by status
        </div>
        <div class="float-right">
          <select
            class="p-3 mr-3 drop-shadow-2xl ring-1 border-blue-800 bg-white py-3 text-sm px-3 rounded-md mr-2 w-48"
            v-model="selectBank"
            @change="handleSelectBank"
          >
            <option value="" disabled>Filter by bank</option>
            <option :value="bank" v-for="(bank, i) in banks" :key="i">
              {{ bank.replaceAll("_", " ") }}
            </option>
          </select>
        </div>
        <div class="float-right">
          <button
            @click="filterToggle = !filterToggle"
            :class="
              filterToggle
                ? 'bg-blue-900 text-white'
                : 'border-blue-50 text-blue-800'
            "
            class="ring-1 inline-block uppercase shadow ml-2 text-xs font-bold text-white focus:shadow-outline focus:outline-none py-3 px-3 rounded float"
          >
            Filter by Date
          </button>
          <button
            @click="searchByToggle = !searchByToggle"
            :class="
              searchByToggle
                ? 'bg-blue-900 text-white'
                : 'bg-blue-50 text-blue-800'
            "
            class="ring-1 inline-block uppercase shadow ml-2 text-xs hover:text-blue-800 font-bold bg-blue-50 text-white hover:bg-blue-100 focus:shadow-outline focus:outline-none py-3 px-3 rounded float"
          >
            Filter By Time
          </button>
        </div>
      </div>
      <div class="my-4 flex bg-gray-100 p-5" v-if="searchByToggle">
        <div class="block">
          <label class="text-xs block font-bold">Time (From)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.starttime"
          />
        </div>
        <div class="block">
          <label class="text-xs block font-bold">Time (To)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.endtime"
          />
        </div>
        <div class="block">
          <button
            class="inline-block drop-shadow-2xl border-2 bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="searchFunc"
          >
            Search
          </button>
        </div>
      </div>
      <div class="my-4 flex bg-gray-100 p-5" v-if="filterToggle">
        <div class="block">
          <label class="text-xs block font-bold">Date (From)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.startdatetime"
          />
        </div>
        <!-- <div class="block">
          <label class="text-xs block font-bold">Date (To)</label>
          <input
            type="date"
            class="inline-block drop-shadow-2xl border-2 border-gray-100 bg-white py-2 px-5 rounded-md mr-2"
            name=""
            id=""
            placeholder="Date"
            v-model="dateData.enddatetime"
          />
        </div> -->

        <div class="block">
          <button
            class="inline-block drop-shadow-2xl border-2 bg-blue-900 text-white py-2 px-3 mt-4 rounded-md mr-2"
            @click="fetchDatedRecords"
          >
            Filter
          </button>
        </div>
      </div>
      <div class="mt-5" style="height: 500px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="bg-blue-100 text-black text-xs whitespace-nowrap text-left px-8 py-2"
              ></th>
              <th
                v-for="(bank, i) in banks"
                :ref="bank"
                :key="i"
                class="bg-blue-100 text-black text-xs whitespace-nowrap text-left px-8 py-2 cursor-pointer"
                @click="
                  $router.push({
                    name: 'ViewTransactionMonitoringByBank',
                    params: {
                      id: bank,
                    },
                  })
                "
              >
                <span
                  class="hover:bg-blue-200 bg-blue-50 rounded-md hover:drop-shadow-lg p-1 cursor-pointer"
                  >{{ bank.replaceAll("_", " ") }}</span
                >
              </th>
            </tr>
            <tr>
              <th class="text-xs whitespace-nowrap text-left"></th>
              <th
                v-for="(bank, i) in banks"
                :key="i"
                class="text-xs whitespace-nowrap text-left"
              >
                <table class="border-0" style="border: 0px">
                  <tr>
                    <th
                      v-for="column in columns"
                      :key="column"
                      style="padding: 10px 10px; border: none"
                      class="w-80"
                    >
                      {{ column }}
                    </th>
                  </tr>
                </table>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(transaction, i) in monitoringData"
              :key="i"
              class="cursor-pointer"
            >
              <td
                class="bostartdatetimerder px-8 py-3 whitespace-nowrap text-xs"
              >
                {{ transaction.response || "N/A" }}
              </td>
              <td
                class="border px-8 py-3 whitespace-nowrap text-xs"
                v-for="(bank, i) in banks"
                :key="i"
              >
                <table class="border-0 w-80" style="border: 0px">
                  <tr>
                    <th
                      v-for="column in columns"
                      :key="column"
                      style="padding: 10px 10px; border: none"
                      class="text-left w-80"
                    >
                      {{
                        transaction[
                          bank.replaceAll(" ", "_") + "_" + column.toUpperCase()
                        ] || "N/A"
                      }}
                    </th>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      isDisabled: false,
      search: "",
      accounts: null,
      filterToggle: false,
      searchByToggle: false,
      dateSelect: null,
      statusSelect: "",
      searchByISAName: "",
      searchByISAID: "",
      fromDateSelect: "",
      toDateSelect: "",
      currentPage: 1,
      dateData: null,
      fetchedData: [],
      selectedBank: "",
      monitoringData: [],
      banks: [],
      columns: ["Mastercard", "Verve", "Visa"],
      selectedRoute: "",
      selectBank: "",
    };
  },
  computed: {},

  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },

  async created() {
    // this.isLoading = true;
    const d = new Date();
    this.dateData = {
      // startdatetime: new Date(d.setDate(d.getDate())).toJSON().split("T")[0],
      startdatetime: new Date(d.setDate(d.getDate() - 1))
        .toJSON()
        .split("T")[0],
      // .split("T")[0],
      enddatetime: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    this.fetchUsingFetch();
  },
  methods: {
    handleSelectBank() {
      const el = this.$refs;
      console.log(el.FCMB);
      console.log(this.selectBank);
      const element = el[this.selectBank][0];
      element.scrollIntoView({ behaviour: "smooth" });
    },
    fetchBanks(data) {
      let allBanks = [];
      data.filter((el) => {
        if (!allBanks.includes(el.bank)) {
          allBanks.push(el.bank);
        }
      });
      allBanks.map((bank) => {
        // bank.replace("");
        this.banks.push(bank);
      });

      // console.log(this.banks.length);
    },
    transformData(data) {
      // let xdata = this.content.views[0].view;
      this.fetchBanks(data);
      let xdata = data;
      let banks = [];
      xdata.forEach((el) => {
        banks.push({
          response: el.response,
          [el.bank + "_" + el.card]: el.errors,
        });
      });

      const result = banks.reduce((acc, value) => {
        const fIndex = acc.findIndex((v) => v.response === value.response);
        if (fIndex >= 0) {
          acc[fIndex] = { ...acc[fIndex], ...value };
        } else {
          acc.push(value);
        }
        return acc;
      }, []);

      this.monitoringData = result;
      // console.log(result);
    },
    async fetchDatedRecords() {
      let date = this.dateData.startdatetime;
      let route = this.$route.params.id;
      let myHeaders = new Headers();
      // myHeaders.append("Access-Control-Allow-Origin", "*");
      // myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        withCredentials: true,
      };

      try {
        this.isLoading = true;
        fetch(
          `http://34.204.53.88:8084/v1/reporting/byroute?date=${date}&route=${route}`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            this.isLoading = false;
            const res = JSON.parse(result);
            this.fetchedData = res.content.views[0].view;
            this.selectedRoute = this.$route.params.id;
            this.transformData(this.fetchedData);
            console.log(res);
          })
          .catch((error) => console.log("error", error));
      } catch (error) {}
    },
    async searchFunc() {
      return;
    },
    async fetchUsingFetch() {
      var myHeaders = new Headers();
      // myHeaders.append("Access-Control-Allow-Origin", "*");
      // myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        withCredentials: true,
      };

      try {
        this.isLoading = true;
        const bank = bank;
        fetch(
          `http://34.204.53.88:8084/v1/reporting/byroute?route=${this.$route.params.id}`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            this.isLoading = false;
            const res = JSON.parse(result);
            this.fetchedData = res.content.views[0].view;
            this.selectedRoute = this.$route.params.id;
            this.transformData(this.fetchedData);
            console.log(res);
          })
          .catch((error) => console.log("error", error));
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.text-amber {
  color: #d08411;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th:first-child {
  /* background: #dee6f2; */
  color: #333;
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
